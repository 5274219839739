import React from 'react'
import WelcomeUser from 'components/Portal/Overview/WelcomeUser/WelcomeUser'
import QuickAccess from 'components/Portal/Overview/QuickAccess/QuickAccess'
function Overview() {
  return (
    <>
        <WelcomeUser/>
        {/* <TotalValues/> */}
        <QuickAccess/>
        {/* <Row>
          <Col lg='9'>
            <div className={styles['table-cont']}>
            <div className={styles['table__header-wrapper']}>
                <h1 className={styles['table__title']}>Students</h1>
                <Link to='/portal/students' className={styles['table__add-button']}>Show All</Link>
            </div>
              <PortalUserTable userData={userData}/>
            </div>
          </Col>
          <Col>
          
          </Col>
        </Row>
        <ReportsInfo/> */}

    </>
  )
}

export default Overview