import React from 'react'
import styles from './PortalTopNavBar.module.css'
import {ReactComponent as MenuIcon} from 'assets/icons/menu2.svg'
import {ReactComponent as LogOtMenuIcon} from 'assets/icons/logOutSideBar.svg'
import logo from 'assets/imgs/logo.png'
import { logOut } from 'store/Login/LoginActions'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import LoggedProfileMenu from 'components/Global/Elements/LoggedProfileMenu/LoggedProfileMenu'

function PortalTopNavBar({closeSideMenu,isSideMenuOpen}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let logOutFunction =()=>{
    Cookies.remove('token')
    Cookies.remove('permissions')
    dispatch(logOut())
    navigate('/')
    // axiosConfig.post('logout',{},{
    //     headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
    // }).then(res=>{
    //     toast.success('Logged Out Successfully')
    //     dispatch(logOut(res.data))
    //     Cookies.remove('token')
    //     Cookies.remove('permissions')
    //     navigate('/')
    // }).catch(err=>{
    //     toast.error(err?.response?.data?.message||'Something went wrong')
    // })
} 
  return (
    <div className={`${styles['portal__top-navbar']} ${isSideMenuOpen?'':styles['portal__top-navbar--closed']}`}>
        <div className={styles['portal__top-navbar-logo-menu-wrapper']}>
          <Link to='/' className={styles['portal__top-navbar__logo-button']}>
            <span className={`${styles['portal__top-navbar__logo-text']} ${isSideMenuOpen?'':styles['portal__top-navbar__logo-text--responsive']}`}>Jerusalem</span>
            <img src={logo} alt='logo' className={`${styles['portal__top-navbar__logo']} ${styles['portal__top-navbar__logo--mini']} mx-4`}/>
          </Link>
          <button className={styles['portal__top-navbar__menu-button']} onClick={closeSideMenu}>
            <MenuIcon className={styles['portal__top-navbar__menu-icon']}/>
          </button>
        </div>
        <div className={styles['portal__top-navbar-rest-icons-wrapper']}>
          <div className='ms-auto'></div>
          <LoggedProfileMenu/>
          <button className={styles['portal__top-navbar__logout-button']} onClick={logOutFunction}>
            <LogOtMenuIcon className={styles['portal__top-navbar__logout-icon']}/>
          </button>
        </div>
    </div>
  )
}

export default PortalTopNavBar