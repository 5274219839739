import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import styles from './AddEditModal.module.css'
import {ReactComponent as ExitIcon} from 'assets/icons/exit.svg'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import Resizer from "react-image-file-resizer";
import { MultiSelect } from 'react-multi-select-component'
import './AddEditModal.css'
import MultiLanguageTitleInput from 'components/Global/Elements/MultiLanguageTitleInput/MultiLanguageTitleInput'

function AddEditRecommendedProgramsModal({handleCloseAddUser, showAddUser, user, getData, places}) {
    const methods = useForm({validate:'onChange'})
    const {register, setError, getValues, handleSubmit, control, setValue, formState:{errors}} = methods
    const [isSubmitting , setIsSubmitting]=useState(false)
    const [image,setImage]=useState([])
    const [options,setOptions] = useState([]);
    // const options = [
    //     { label: "Grapes 🍇", value: "grapes" },
    //     { label: "Mango 🥭", value: "mango" },
    //     { label: "Strawberry 🍓", value: "strawberry", disabled: true },
    // ];
    useEffect(()=>{
        let options = places?.map(place=>{
            return { label: place?.title, value:place?.id }
        })
        setOptions(options)
    }, [places])

    async function handleUploadedImage(e){
        let image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
            e.target.files[0],
            200,
            200,
            "JPEG",
            50,
            0,
            (uri) => {
                console.log(uri)
                resolve(uri);
            },
            "file",
            200,
            200,
        );
        });
        Object.assign(image, {
            preview: URL.createObjectURL(image),
        })
        setImage(image)
    }

    const submitForm =(data)=>{
        let formData =new FormData()
        Object.keys(getValues()).forEach(key=>{
            if(key !=='places')
                formData.append(`${key}`,getValues()[key])
        })
        formData.append(`title_en`,getValues()?.['titles']?.['en'])
        formData.append(`title_ar`,getValues()?.['titles']?.['ar'])
        formData.append(`title_fr`,getValues()?.['titles']?.['fr'])
        formData.append(`title_de`,getValues()?.['titles']?.['de'])
        formData.append(`title_es`,getValues()?.['titles']?.['es'])
        formData.append(`title_tr`,getValues()?.['titles']?.['tr'])
        formData.append(`image`,image)
        getValues('places')?.forEach((lang, index)=>{
            formData.append(`places[${index}]`,lang?.value)
        })
        setIsSubmitting(true)
        if(user){
            axiosConfig.put(`recommended-programs/update-recommended-program/${user?.id}`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Recommended Program Updated Successfully')
                handleCloseAddUser()
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                toast.error(err?.response?.data?.message||'Something went wrong')
                // handleCloseAddUser()
                setIsSubmitting(false)
            })
        }else{
            axiosConfig.post(`recommended-programs/create-recommended-program`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Recommended Program Added Successfully')
                handleCloseAddUser()
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                // toast.error(err?.response?.data?.message||'Something went wrong')
                setIsSubmitting(false)
                let errors = err.response.data?.data?.[0]
                console.log(err.response.data?.data)
                Object?.keys(errors)?.forEach(error=>{
                    toast.error(errors[error])
                })
                // handleCloseAddUser()
            })

        }
    }

    useEffect(()=>{
        setValue('titles',user?.titles)
        setValue('days',user?.days)
        setValue('show_order_number',user?.show_order_number)
        setImage(user?.image)
        // setValue('description',group?.description)
    }, [user])
    useEffect(()=>{
        if (user && places?.length > 0) {
            let values = places?.map((place) => {
                if (user?.places_id?.includes(place?.id)) {
                    return { label:place?.title, value:place?.id }
                }
            })?.filter(lang=>lang)
            setValue('places',values)
        }
        // setValue('description',group?.description)
    }, [user, places])

    // useEffect(()=>{
    //     setValue('group_id',searchParams?.get('group'))
    // },[searchParams])
  return (
    <>
        <Modal show={showAddUser} onHide={handleCloseAddUser}  size="lg">
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>{user?'Update':'Add'} Recommended Programs</h2>
                <button type="button" className={`${styles["close"]} close ms-auto`} onClick={handleCloseAddUser}>
                    <ExitIcon className={styles['modal__exit-icon']}/>
                </button>
            </div>
            
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className={`${styles["modal-body"]} modal-body`}>
                        <Row>
                            <MultiLanguageTitleInput/>

                            <Col xs='6' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='daysInput'>
                                        Days <span className={styles['modal__form-input-required']}>*</span>
                                    </label>
                                    <input 
                                        type='number' 
                                        className={`${styles['modal__form-input']} ${errors?.days ?styles['modal__form-input--error']:''}`} 
                                        placeholder='Please enter Days' 
                                        id='daysInput'
                                        {...register('days',{required:'Days is required'})}
                                    />
                                    {errors?.days &&<span className={styles['modal__form-input-error-message']}>{errors.days?.message}</span>}
                                </div>
                            </Col>

                            <Col xs='6' className='mb-3'>
                                <div className={`${styles['modal__form-input-wrapper']} h-100 `} id='langugeWrapperid'>
                                    <label className={styles['modal__form-input-label']} htmlFor='placesInput'>
                                        Places
                                    </label>
                                        <Controller 
                                            render={({name })=><MultiSelect
                                                className='h-100'
                                                name={name}
                                                options={options}
                                                value={getValues(`places`)||[]}
                                                onChange={(data)=> setValue(`places`,data)}  
                                                labelledBy="Select"
                                            />}
                                            name='places'
                                            control={control}
                                        />
                                </div>
                            </Col>

                            <Col xs='6' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='showOrderNumberInput'>
                                        Listing Number <span className={styles['modal__form-input-required']}>*</span>
                                    </label>
                                    <input 
                                        type='text' 
                                        className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                        placeholder='Please enter Listing Number' 
                                        id='showOrderNumberInput'
                                        {...register('show_order_number',{required:'Listing Number is required'})}
                                    />
                                    {errors?.show_order_number &&<span className={styles['modal__form-input-error-message']}>{errors.show_order_number?.message}</span>}
                                </div>
                            </Col>

                            {/* <Col xs='6' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                        Status <span className={styles['modal__form-input-required']}>*</span>
                                    </label>
                                    <select
                                        className={`${styles['modal__form-input']} px-2 ${errors?.status ?styles['modal__form-input--error']:''}`} 
                                        {...register('status',{required:'User Status is required'})}
                                    >
                                        <option value='1' selected>Active</option>
                                        <option value='2'>Not Active</option>
                                    </select>
                                    {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>}
                                </div>
                            </Col> */}
                            <Col xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                        Image 
                                    </label>
                                    <input
                                        type='file'
                                        data-kt-user-table-filter='search'
                                        className='form-control form-control-solid mb-3 ps-14'
                                        placeholder='Type Icon'
                                        id='carImage'
                                        onChange={(e) => handleUploadedImage(e)}
                                        accept={'.jpg,.png,.gif,.jpeg'}
                                    />
                                    {
                                        image&&(image?.preview||image?.length!=0)&&
                                            <div>
                                                <div className='mb-2 d-flex align-items-center justify-content-between'>
                                                    <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                                    <button onClick={()=>{setImage(null)}}
                                                    className='btn btn-danger ms-auto'>Delete</button>
                                                </div>
                                            </div>
                                    }
                                    {/* {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>} */}
                                </div>
                            </Col>
                        </Row>
                        <div className={styles['submit-modal__buttons-cont']}>
                            <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                        </div>
                    </div>
                </form>
            </FormProvider>

        </Modal>
    </>
  )
}

export default AddEditRecommendedProgramsModal