import React from 'react'
import { Spinner } from 'react-bootstrap'
function TableLoading() {
  return (
    <>
      <div className='w-100 d-flex'>
          <div className="loading-indicator m-auto">
              <Spinner animation="border" variant='primary' color='#007FAB' />
          </div>
      </div>
    </>
  )
}

export default TableLoading