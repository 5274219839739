import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './ComparePlace.module.css'
import { FormProvider, useForm } from 'react-hook-form'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from 'react-router-dom'

function ComparePlace() {
    const methods = useForm({validate:'onChange'})
    const { handleSubmit } = methods

    const {id} = useParams();
    const navigate = useNavigate();
    
    const [isSubmitting , setIsSubmitting]=useState(false)
    const [place,setPlace] =useState(null)
    const [image,setImage]=useState([])

    async function handleUploadedImage(e){
        let image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
            e.target.files[0],
            200,
            200,
            "JPEG",
            50,
            0,
            (uri) => {
                console.log(uri)
                resolve(uri);
            },
            "file",
            200,
            200,
        );
        });
        Object.assign(image, {
            preview: URL.createObjectURL(image),
        })
        setImage(image)
    }
    

    const submitForm =(data)=>{
        let formData =new FormData()
        formData.append(`compared_image`,image)
        setIsSubmitting(true)
        
            axiosConfig.post('places/compare-place',formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`, "content-type":"multipart/form-data"}
            }).then(res=>{
                toast.success('Place Found Successfully')
                setImage([])
                setIsSubmitting(false)
                // navigate('/places')
            }).catch(err=>{
                setIsSubmitting(false)
                let errors = err?.response?.data?.data?.[0]
                console.log(err?.response?.data?.data)
                if (errors) {
                    Object?.keys(errors || {})?.forEach(error=>{
                        toast.error(errors?.[error])
                    })
                } else {
                    toast.error(err?.response?.data?.message||'Something went wrong')
                }
            })
    }

  return (
    <>
    <section>
        {/* <ListingHeader/> */}
        <div className={styles['table-cont']}>
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>{id ? "Update" : "Add"} Place</h2>
            </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className={`${styles["modal-body"]} modal-body`}>
                        <Row>
                            
                            <Col xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                        Image 
                                    </label>
                                    <input
                                        type='file'
                                        data-kt-user-table-filter='search'
                                        className='form-control form-control-solid mb-3 ps-14'
                                        placeholder='Type Icon'
                                        id='carImage'
                                        onChange={(e) => handleUploadedImage(e)}
                                        accept={'.jpg,.png,.gif,.jpeg'}
                                    />
                                    {
                                        image&&(image?.preview||image?.length!=0)&&
                                            <div>
                                                <div className='mb-2 d-flex align-items-center justify-content-between'>
                                                    <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                                    {/* <button type='button' onClick={()=>{setImage(null)}}
                                                    className='btn btn-danger ms-auto'>Delete</button> */}
                                                </div>
                                            </div>
                                    }
                                    {/* {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>} */}
                                </div>
                            </Col>
                        </Row>
                        <div className={styles['submit-modal__buttons-cont']}>
                            <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                        </div>
                        {
                            place && <>
                                <Row>
                                    <Col xs='6' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='showOrderNumberInput'>
                                                Place Title
                                            </label>
                                            <p className={styles['modal__form-input-text']}>{place?.title}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </form>
            </FormProvider>
        </div>
    </section>
        {/* <div className='table-responsive'>

        </div> */}
    </>
  )
}

export default ComparePlace