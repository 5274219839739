import React from 'react'
import styles from './WelcomeUser.module.css'
import {ReactComponent as MembersPortalIcon} from 'assets/icons/materialsPortal.svg'
import { useSelector } from 'react-redux'
function WelcomeUser() {
  const user = useSelector(state=>state.LoginReducer.user)
  return (
    <div className={styles['overview__welcome-user']}>
        <div className={styles['overview__welcome-user-type-wrapper']}>
          <MembersPortalIcon className={styles['overview__welcome-user-type-icon']}/>
        </div>
        <div className={styles['overview__welcome-user-info']}>
          <h2 className={styles['overview__welcome-user-info-title']}>
            {
              user?.user_type === "teacher"?'Teacher ':
              user?.user_type === "student"?'Student ':''
            }
            Portal
          </h2>

          <h3 className={styles['overview__welcome-user-info-welcome']}>Welcome {user?.name} In your dashboard</h3>
        </div>
        <div>

        </div>
    </div>

  )
}

export default WelcomeUser