import React, { useState } from 'react'
import {ReactComponent as AddIcon} from 'assets/icons/addIcon.svg'
import styles from './TableHeader.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
function DestinationsTableHeader({getData}) {
  const navigate = useNavigate()
  
  const [searchParams,setSearchParams] = useSearchParams()
  const [showAddCategory, setShowAddCategory] = useState(false);

  const handleCloseAddCategory = () => setShowAddCategory(false);
  const handleShowAddCategory = () => navigate(`/add-destination?category_id=${searchParams?.get('category_id')}`);
  return (
    // <div className={styles['table__header-wrapper']}>
    <>
        {/* <h1 className={styles['table__title']}>Students</h1> */}
        <button className={styles['table__add-button']} onClick={handleShowAddCategory}>
            <AddIcon className={styles['table__add-icon']}/>Add Location
        </button>
        {/* <AddEditDestinationModal showAddCategory={showAddCategory} handleCloseAddCategory={handleCloseAddCategory} getData={getData}/> */}
    </>
    // </div>
  )
}

export default DestinationsTableHeader