import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './AddEditDestination.module.css'
import { FormProvider, useForm } from 'react-hook-form'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import Resizer from "react-image-file-resizer";
import MultiLanguageTitleInput from 'components/Global/Elements/MultiLanguageTitleInput/MultiLanguageTitleInput'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import MultiLanguageDescriptionAdvancedInput from 'components/Global/Elements/MultiLanguageDescriptionAdvancedInput/MultiLanguageDescriptionAdvancedInput'
import GoogleSearchBoxWithMap from 'components/Global/Elements/GoogleSearchBoxWithMap/GoogleSearchBoxWithMap'

function AddEditDestination() {
    const methods = useForm({validate:'onChange'})
    const [searchParams,setSearchParams] = useSearchParams()
    const {register ,getValues,handleSubmit,setValue,formState:{errors}} = methods

    const {id} = useParams();
    const navigate = useNavigate();
    
    const [isSubmitting , setIsSubmitting]=useState(false)
    const [latitude,setLatitude] =useState('')
    const [longitude,setLongitude] =useState('')
    const [image,setImage]=useState([])
    const [images,setImages]=useState([])
    const [item, setItem] = useState();
    const [isLoadingData, setIsLoadingData] = useState('');
    const [categories, setCategories] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);

    const getNeighborhoods =()=>{
        axiosConfig.get(`neighborhood/all-neighborhoods`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}` , 'accept-language': 'en'}
        }).then(res=>{
            setNeighborhoods(res?.data?.data)
        }).catch(err=>{
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
                toast.error(errors[error][0])
            })
        })
    }

    const getCategories =()=>{
        axiosConfig.get(`category/all-categories`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}` , 'accept-language': 'en'}
        }).then(res=>{
            setCategories(res?.data?.data)
        }).catch(err=>{
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
                toast.error(errors[error][0])
            })
        })
    }

    const getData =()=>{
        setIsLoadingData(true)
        axiosConfig.get(`destinations/single-destination/${id}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}` , 'accept-language': 'en'}
        }).then(res=>{
            setIsLoadingData(false)
            setItem(res?.data?.data)
            setLatitude(res.data.data?.latitude)
            setLongitude(res.data.data?.longitude)
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
                toast.error(errors[error][0])
            })
        })
    }

    useEffect(()=>{
        if(id) getData()
    }, [id])

    useEffect(()=>{
        console.log("searchParams", searchParams?.get('category_id'))
        setValue('category_id', searchParams?.get('category_id'))
    }, [searchParams, categories])

    useEffect(()=>{
        getCategories()
        getNeighborhoods()
    }, [])

    async function handleUploadedImage(e){
        let image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
            e.target.files[0],
            200,
            200,
            "JPEG",
            50,
            0,
            (uri) => {
                console.log(uri)
                resolve(uri);
            },
            "file",
            200,
            200,
        );
        });
        Object.assign(image, {
            preview: URL.createObjectURL(image),
        })
        setImage(image)
    }
    
    async function handleUploadedImages(e){
        let files =e.target.files
        let uploadedFile = [...images]
        if((files?.length+images?.length)>8){
            toast.error("You Can't upload more than 8 images")
            return
        }
        files = await Promise.all([...files ].map(async(file) =>{
            let image = await new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    500,
                    500,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        console.log(uri)
                        resolve(uri);
                    },
                    "file"
                );
            });
            Object.assign(image, {
                preview: URL.createObjectURL(image),
            })
            uploadedFile.push(image)
        }))
        setImages(uploadedFile)
    }
    
    function deleteImg(index){
        let modifiedImages=[...images]
        modifiedImages.splice(index,1)
        setImages(modifiedImages)
    }

    const submitForm =(data)=>{
        let formData =new FormData()
        formData.append(`title_en`,getValues()?.['titles']?.['en'])
        formData.append(`title_ar`,getValues()?.['titles']?.['ar'])
        formData.append(`title_fr`,getValues()?.['titles']?.['fr'])
        formData.append(`title_de`,getValues()?.['titles']?.['de'])
        formData.append(`title_es`,getValues()?.['titles']?.['es'])
        formData.append(`title_tr`,getValues()?.['titles']?.['tr'])
        formData.append(`description_en`,getValues()?.['descriptions']?.['en'])
        formData.append(`description_ar`,getValues()?.['descriptions']?.['ar'])
        formData.append(`description_fr`,getValues()?.['descriptions']?.['fr'])
        formData.append(`description_de`,getValues()?.['descriptions']?.['de'])
        formData.append(`description_es`,getValues()?.['descriptions']?.['es'])
        formData.append(`description_tr`,getValues()?.['descriptions']?.['tr'])
        formData.append(`phone_number`,getValues()['phone_number'])  
        formData.append(`order_number`,getValues()['showOrderNumber'])  
        formData.append(`category_id`,getValues()['category_id'])  
        formData.append(`neighborhood_id`,getValues()['neighborhood_id'])  
        formData.append(`latitude`, latitude)
        formData.append(`longitude`, longitude)
        formData.append(`image`,image)
        images && images?.forEach(img => {
            formData.append(`images`,img)
        })

        setIsSubmitting(true)
        if(item) {
            
            axiosConfig.put(`destinations/update-destination/${item?.id}`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Destination Updated Successfully')
                setImage([])
                setIsSubmitting(false)
                getData()
                navigate('/destinations')
            }).catch(err=>{ 
                setIsSubmitting(false)
                let errors = err?.response?.data?.data?.[0]
                console.log(err?.response?.data?.data)
                if (errors) {
                    Object?.keys(errors || {})?.forEach(error=>{
                        toast.error(errors?.[error])
                    })
                } else {
                    toast.error(err?.response?.data?.message||'Something went wrong')
                }
            })
        } else {
            axiosConfig.post('destinations/create-destination',formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Destination Added Successfully')
                setImage([])
                setIsSubmitting(false)
                getData()
                navigate('/destinations')
            }).catch(err=>{
                setIsSubmitting(false)
                let errors = err?.response?.data?.data?.[0]
                console.log(err?.response?.data?.data)
                if (errors) {
                    Object?.keys(errors || {})?.forEach(error=>{
                        toast.error(errors?.[error])
                    })
                } else {
                    toast.error(err?.response?.data?.message||'Something went wrong')
                }
            })
        }
    }

    useEffect(()=>{
        if (item) {
            console.log("searchParamssss")
            setValue('titles',item?.titles)
            setValue('descriptions',item?.descriptions)
            setValue('phone_number',item?.phone_number)
            setValue('latitude',item?.latitude)
            setValue('longitude',item?.longitude)
            setValue('showOrderNumber',item?.order_number)
            setValue('category_id',item?.category_id)
            setValue('neighborhood_id',item?.neighborhood_id)
            setImage(item?.image)
            setImages(item?.images || [])
        }
    }, [item])

    return (
        <>
            <section>
                {/* <ListingHeader/> */}
                <div className={styles['table-cont']}>
                    <div className={`${styles["modal-header"]} modal-header`}>
                        <h2 className={styles['modal__title']}>{id ? "Update" : "Add"} Destination</h2>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className={`${styles["modal-body"]} modal-body`}>
                                <Row>
                                    <MultiLanguageTitleInput/>
                                    <hr/>
                                    <MultiLanguageDescriptionAdvancedInput keyValue='descriptions'/>
                                    <hr/>

                                    <Col xs='6' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='phoneNumberInput'>
                                                Phone Number <span className={styles['modal__form-input-required']}>*</span>
                                            </label>
                                            <input 
                                                type='text' 
                                                className={`${styles['modal__form-input']} w-100 ${errors?.phone_number ?styles['modal__form-input--error']:''}`} 
                                                placeholder='Please enter Phone Number' 
                                                id='phoneNumberInput'
                                                {...register('phone_number',{required:'Phone Number is required'})}
                                            />
                                            {errors?.phone_number &&<span className={styles['modal__form-input-error-message']}>{errors.phone_number?.message}</span>}
                                        </div>
                                    </Col>
                                    <Col xs='6' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='showOrderNumberInput'>
                                                Show Order Number <span className={styles['modal__form-input-required']}>*</span>
                                            </label>
                                            <input 
                                                type='text' 
                                                className={`${styles['modal__form-input']} ${errors?.showOrderNumber ?styles['modal__form-input--error']:''}`} 
                                                placeholder='Please enter Category Title' 
                                                id='showOrderNumberInput'
                                                {...register('showOrderNumber',{required:'Category Title is required'})}
                                            />
                                            {errors?.showOrderNumber &&<span className={styles['modal__form-input-error-message']}>{errors.showOrderNumber?.message}</span>}
                                        </div>
                                    </Col>

                                    {/* <Col xs='6' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                                Status <span className={styles['modal__form-input-required']}>*</span>
                                            </label>
                                            <select
                                                // className='form-select mt-3'
                                                className={`${styles['modal__form-input']} px-2 ${errors?.status ?styles['modal__form-input--error']:''}`} 
                                                {...register('status',{required:'Category Status is required'})}
                                            >
                                                <option value=''>Please Select Active Status</option>
                                                <option value='1' selected>Active</option>
                                                <option value='2'>Not Active</option>
                                            </select>
                                            {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>}
                                        </div>
                                    </Col> */}
                                    <Col xs='6' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                                Category
                                            </label>
                                            <select
                                                className={`${styles['modal__form-input']} px-2 ${errors?.category_id ?styles['modal__form-input--error']:''}`} 
                                                {...register('category_id')}
                                            >
                                                <option value=''>Please Select Category</option>
                                                {
                                                    categories && categories?.map(category=>(
                                                        <option value={category?.id} key={category?.id}>{category?.title}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors?.category_id &&<span className={styles['modal__form-input-error-message']}>{errors.category_id?.message}</span>}
                                        </div>
                                    </Col>
                                    <Col xs='6' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                                Location
                                            </label>
                                            <select
                                                className={`${styles['modal__form-input']} px-2 ${errors?.neighborhood_id ?styles['modal__form-input--error']:''}`} 
                                                {...register('neighborhood_id')}
                                            >
                                                <option value=''>Please Select Location</option>
                                                {
                                                    neighborhoods && neighborhoods?.map(neighborhood=>(
                                                        <option value={neighborhood?.id} key={neighborhood?.id}>{neighborhood?.title}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors?.neighborhood_id &&<span className={styles['modal__form-input-error-message']}>{errors.neighborhood_id?.message}</span>}
                                        </div>
                                    </Col>
                                    <Col xs='12' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                                Image 
                                            </label>
                                            <input
                                                type='file'
                                                data-kt-user-table-filter='search'
                                                className='form-control form-control-solid mb-3 ps-14'
                                                placeholder='Type Icon'
                                                id='carImage'
                                                onChange={(e) => handleUploadedImage(e)}
                                                accept={'.jpg,.png,.gif,.jpeg'}
                                            />
                                            {
                                                image&&(image?.preview||image?.length!=0)&&
                                                    <div>
                                                        <div className='mb-2 d-flex align-items-center justify-content-between'>
                                                            <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                                            <button onClick={()=>{setImage(null)}}
                                                            className='btn btn-danger ms-auto'>Delete</button>
                                                        </div>
                                                    </div>
                                            }
                                            {/* {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>} */}
                                        </div>
                                    </Col>
                                    
                                    <Col xs='12' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                                Images
                                            </label>
                                            <input
                                                type='file'
                                                data-kt-user-table-filter='search'
                                                className='form-control form-control-solid mb-3 ps-14'
                                                placeholder='Type Icon'
                                                id='carImage'
                                                accept={'.jpg,.png,.gif,.jpeg'}
                                                multiple
                                                onChange={(e) => handleUploadedImages(e)}
                                            />
                                            {
                                                    images&&images.map((img,index)=>(
                                                        <div key={index} className='mb-5 d-flex align-items-center justify-content-between'>
                                                            <img src={img?.preview ?img?.preview:img} alt='img' style={{width:'100px',height:'100px'}}/>
                                                            <button  type='button' onClick={()=>{deleteImg(index)}}
                                                            className='btn btn-danger ms-auto'>
                                                                Delete
                                                            </button>
                                                        </div>
                                                    ))
                                                }
                                            
                                        </div>
                                    </Col>

                                    <Col xs='12' className='mb-3'>
                                        <div className={styles['modal__form-input-wrapper']}>
                                            <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                                Location 
                                            </label>
                                            <GoogleSearchBoxWithMap 
                                                setLatitude={setLatitude} 
                                                setLongitude={setLongitude} 
                                                isItemLoading={isLoadingData} 
                                                longitude={longitude} 
                                                latitude={latitude}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className={styles['submit-modal__buttons-cont']}>
                                    <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </section>
            {/* <div className='table-responsive'>

            </div> */}
        </>
    )
}

export default AddEditDestination