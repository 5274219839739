import PortalTablePagination from 'components/Global/Elements/PortalTablePagination/PortalTablePagination'
import React, { useEffect, useState } from 'react'
import styles from './Users.module.css'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import ListingHeader from 'components/Global/Elements/ListingHeader/ListingHeader'
import PortalUserTable from 'components/Portal/User/PortalTable/PortalTable'
import UserTableHeader from 'components/Portal/User/TableHeader/TableHeader'

function Users() {
    const [userData ,setUserData] =useState([])
    const [isLoadingData ,setIsLoadingData] =useState(false)
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page)
    };

    const getData =(page)=>{
        setIsLoadingData(true)
        axiosConfig.get(`user/all-users?page=${page}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            setIsLoadingData(false)
            // setCategories(res.data.data)
            setUserData(res?.data?.data?.data)
            setTotalPages(res?.data?.data?.pages)
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
            toast.error(errors[error][0])
            })
        })
    }
    useEffect(()=>{
        getData(1)
    }, [])

    return (
        <section>
            <ListingHeader/>
            <div className={styles['table-cont']}>
                <div className={styles['table__header-wrapper']}>
                    <h1 className={styles['table__title']}>Users</h1>
                    <UserTableHeader getData={getData}/>
                    {/* <AddUserModal/> */}
                </div>
                <PortalUserTable userData={userData} getData={getData} isLoadingData={isLoadingData}/>
                {/* <PortalTablePagination/> */}
                <PortalTablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        </section>
    )
}

export default Users