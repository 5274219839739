import React, { useEffect, useState } from 'react'
import styles from './Settings.module.css'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import ListingHeader from 'components/Global/Elements/ListingHeader/ListingHeader'
import AddEditSettingsForm from 'components/Portal/Settings/AddEditModal/AddEditModal'

function Settings() {

    const [userData ,setUserData] =useState([])
    const [isLoadingData ,setIsLoadingData] =useState(false)
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [categories, setCategories] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page)
    };

    const getData =(page)=>{
        setIsLoadingData(true)
        axiosConfig.get(`settings?page=${page}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            setIsLoadingData(false)
            // setCategories(res.data.data)
            setUserData(res?.data?.data)
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
            toast.error(errors[error][0])
            })
        })
    }
    const getCategories =(data)=>{
        axiosConfig.get(`category/all-categories`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`, 'accept-language':'en'}
        }).then(res=>{
            setCategories(res.data.data)
        }).catch(err=>{
        })
    }
    useEffect(()=>{
        getData(1)
        getCategories()
    }, [])

    return (
        <section>
            <ListingHeader/>
            <div className={styles['table-cont']}>
                <div className={styles['table__header-wrapper']}>
                    <h1 className={styles['table__title']}>Settings</h1>
                    {/* <UserTableHeader getData={getData}/> */}
                </div>
                <AddEditSettingsForm settings={userData} getData={getData} isLoadingData={isLoadingData} categories={categories}/>
                {/* <PortalTablePagination/> */}
                {/* <PortalTablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                /> */}
            </div>
        </section>
    )
}

export default Settings