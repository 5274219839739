import React, { useEffect, useRef, useState } from 'react'
import styles from './PortalSideNavBar.module.css'
import { Link } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import {ReactComponent as DownArrowIcon} from 'assets/icons/downArrow.svg'
import {ReactComponent as SettingsIcon} from 'assets/icons/settings.svg'
import {ReactComponent as SliderIcon} from 'assets/icons/slider.svg'
import {ReactComponent as UsersIcon} from 'assets/icons/users.svg'
import {ReactComponent as PlacesIcon} from 'assets/icons/places.svg'
import { useSelector } from 'react-redux'
import { axiosConfig } from 'utils/axiosConfig'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

function PortalSideNavBar({isSideMenuOpen}) {
  const accordionRef =useRef(null)
  const [activeKey,setActiveKey] =useState(null)
  const [categories,setCategories] =useState(null)
  const user = useSelector(state=>state.LoginReducer.user)
  // useEffect(()=>{
  //   let accordionCollapse = document.querySelectorAll('.accordion-button:not(.collapsed)')
  //   if(!isSideMenuOpen){
  //     accordionCollapse.forEach(accordionItem=>{
  //       accordionItem.click()
  //     })
  //   }
  // },[isSideMenuOpen])
  
  const getCategories =()=>{
    axiosConfig.get(`category/all-categories`,{
        headers: {"Authorization":`Bearer ${Cookies.get('token')}` , 'accept-language': 'en'}
    }).then(res=>{
        setCategories(res?.data?.data)
    }).catch(err=>{
        let errors = err.response.data.errors
        Object.keys(errors).forEach(error=>{
            toast.error(errors[error][0])
        })
    })
  }
  useEffect(()=> {
    getCategories()
  }, [])
  return (
    <div className={`${styles['portal__side-navbar-content-wrapper']} ${isSideMenuOpen?'':styles['portal__side-navbar-content-wrapper--closed']}`}>
      <Accordion ref={accordionRef} onSelect={(e)=> setActiveKey(e) }> 
        {/* <h1 className={styles['portal__side-navbar-header']}>Dashboard</h1> */}
        
            {/* Users & Admin Users & tour-guides */}
            <Accordion.Item eventKey="1" className={styles['portal__accordion-item']}>
              <Accordion.Header className={`${styles['portal__side-navbar-item-wrapper']}`}>
                <div className={styles['portal__accordion-header-content']}>
                  <div className={styles['portal__accordion-header-item-cont']}>
                      <UsersIcon className={styles['portal__side-navbar-item-icon']}/>
                      <span className={styles['portal__accordion-header']}>Users</span>
                  </div>
                  <DownArrowIcon
                    className={`${styles['portal__accordion-arrow-icon']}
                      ${activeKey === "1" ?styles['portal__accordion-arrow-icon--active']:''}`}
                    />
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles['portal__accordion-body']}>
                <ul className={styles['portal__accordion-list']}>
                  <Link to='/users' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/users' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Users</span>
                      </div>
                    </div>
                  </Link>
                  {/* <Link to='/tour-guides' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/tour-guides' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Tour Guides</span>
                      </div>
                    </div>
                  </Link> */}
                  <Link to='/admin-users' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/admin-users' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Admin Users</span>
                      </div>
                    </div>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
        
            {/* Tour Guides */}
            <Accordion.Item eventKey="2" className={styles['portal__accordion-item']}>
              <Accordion.Header className={`${styles['portal__side-navbar-item-wrapper']}`}>
                <div className={styles['portal__accordion-header-content']}>
                  <div className={styles['portal__accordion-header-item-cont']}>
                      <UsersIcon className={styles['portal__side-navbar-item-icon']}/>
                      <span className={styles['portal__accordion-header']}>Tour Guides</span>
                  </div>
                  <DownArrowIcon
                    className={`${styles['portal__accordion-arrow-icon']}
                      ${activeKey === "2" ?styles['portal__accordion-arrow-icon--active']:''}`}
                    />
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles['portal__accordion-body']}>
                <ul className={styles['portal__accordion-list']}>
                  <Link to='/tour-guides' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/tour-guides' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Tour Guides</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/languages' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/languages' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Languages</span>
                      </div>
                    </div>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Places & Compare Place & Recommended Programs & Categories & Destinations & Neighborhoods */}
            <Accordion.Item eventKey="3" className={styles['portal__accordion-item']}>
              <Accordion.Header className={`${styles['portal__side-navbar-item-wrapper']}`}>
                <div className={styles['portal__accordion-header-content']}>
                  <div className={styles['portal__accordion-header-item-cont']}>
                      <PlacesIcon className={styles['portal__side-navbar-item-icon']}/>
                      <span className={styles['portal__accordion-header']}>
                          Places
                      </span>
                  </div>
                  <DownArrowIcon
                    className={`${styles['portal__accordion-arrow-icon']}
                      ${activeKey === "3" ?styles['portal__accordion-arrow-icon--active']:''}`}
                    />
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles['portal__accordion-body']}>
                <ul className={styles['portal__accordion-list']}>
                  <Link to='/places' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/places' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Places</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/recommended-programs' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/recommended-programs' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Recommended Programs</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/categories' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/categories' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Categories</span>
                      </div>
                    </div>
                  </Link>
                  {/* <Link to='/destinations' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/destinations' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Destinations</span>
                      </div>
                    </div>
                  </Link> */}
                  <Link to='/neighborhoods' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/neighborhoods' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Locations</span>
                      </div>
                    </div>
                  </Link>
                  {/* <Link to='/compare-places' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/compare-places' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Compare Places</span>
                      </div>
                    </div>
                  </Link> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            
            {/* Settings & Static Pages & Contact Us & Languages */}
            <Accordion.Item eventKey="4" className={styles['portal__accordion-item']}>
              <Accordion.Header className={`${styles['portal__side-navbar-item-wrapper']}`}>
                <div className={styles['portal__accordion-header-content']}>
                  <div className={styles['portal__accordion-header-item-cont']}>
                      <SettingsIcon className={styles['portal__side-navbar-item-icon']}/>
                      <span className={styles['portal__accordion-header']}>Settings</span>
                  </div>
                  <DownArrowIcon
                    className={`${styles['portal__accordion-arrow-icon']}
                      ${activeKey === "4" ?styles['portal__accordion-arrow-icon--active']:''}`}
                    />
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles['portal__accordion-body']}>
                <ul className={styles['portal__accordion-list']}>
                  <Link to='/settings' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/settings' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Settings</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/static-pages' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/static-pages' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Static Pages</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/contact-us' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/contact-us' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Contact Us</span>
                      </div>
                    </div>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            
            {/* SLiders & Onboard Slider */}
            <Accordion.Item eventKey="5" className={styles['portal__accordion-item']}>
              <Accordion.Header className={`${styles['portal__side-navbar-item-wrapper']}`}>
                <div className={styles['portal__accordion-header-content']}>
                  <div className={styles['portal__accordion-header-item-cont']}>
                      <SliderIcon className={styles['portal__side-navbar-item-icon']}/>
                      <span className={styles['portal__accordion-header']}>Slider</span>
                  </div>
                  <DownArrowIcon
                    className={`${styles['portal__accordion-arrow-icon']}
                      ${activeKey === "5" ?styles['portal__accordion-arrow-icon--active']:''}`}
                    />
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles['portal__accordion-body']}>
                <ul className={styles['portal__accordion-list']}>
                  <Link to='/slider' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/slider' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Slider</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/on-board-slider' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/on-board-slider' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Onboard Slider</span>
                      </div>
                    </div>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Places & Compare Place & Recommended Programs & Categories & Destinations & Neighborhoods */}
            <Accordion.Item eventKey="6" className={styles['portal__accordion-item']}>
              <Accordion.Header className={`${styles['portal__side-navbar-item-wrapper']}`}>
                <div className={styles['portal__accordion-header-content']}>
                  <div className={styles['portal__accordion-header-item-cont']}>
                      <PlacesIcon className={styles['portal__side-navbar-item-icon']}/>
                      <span className={styles['portal__accordion-header']}>
                          Destinations
                      </span>
                  </div>
                  <DownArrowIcon
                    className={`${styles['portal__accordion-arrow-icon']}
                      ${activeKey === "6" ?styles['portal__accordion-arrow-icon--active']:''}`}
                    />
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles['portal__accordion-body']}>
                <ul className={styles['portal__accordion-list']}>
                  {
                    categories && categories?.map(category => (
                      <Link to={`/destinations?category_id=${category?.id}`} key={category?.id} className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname===`/destinations?category_id=${category?.id}` ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                        <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                          <div className={styles['portal__accordion-header-item-cont']}>
                              <span className={styles['portal__accordion-header']}>{category?.title}</span>
                          </div>
                        </div>
                      </Link>
                    ))
                  }
                  {/* <Link to='/recommended-programs' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/recommended-programs' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Recommended Programs</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/categories' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/categories' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Categories</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/destinations' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/destinations' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Destinations</span>
                      </div>
                    </div>
                  </Link>
                  <Link to='/neighborhoods' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/neighborhoods' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Locations</span>
                      </div>
                    </div>
                  </Link> */}
                  {/* <Link to='/compare-places' className={`${styles['portal__side-navbar-item-wrapper']} ${window.location.pathname==='/compare-places' ?styles['portal__side-navbar-item-wrapper--active']:''}`}>
                    <div className={`${styles['portal__accordion-header-content']} ${styles['portal__accordion-header-content--nested']}`}>
                      <div className={styles['portal__accordion-header-item-cont']}>
                          <span className={styles['portal__accordion-header']}>Compare Places</span>
                      </div>
                    </div>
                  </Link> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

      </Accordion>
    </div>
    
  )
}

export default PortalSideNavBar