import PortalTablePagination from 'components/Global/Elements/PortalTablePagination/PortalTablePagination'
import React, { useEffect, useState } from 'react'
import styles from './RecommendedPrograms.module.css'
import { axiosConfig } from 'utils/axiosConfig'

import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import ListingHeader from 'components/Global/Elements/ListingHeader/ListingHeader'
import RecommendedProgramsTableHeader from 'components/Portal/RecommendedPrograms/TableHeader/TableHeader'
import PortalRecommendedProgramsTable from 'components/Portal/RecommendedPrograms/PortalTable/PortalTable'

function RecommendedPrograms() {

    const [userData ,setUserData] =useState([])
    const [places ,setPlaces] =useState([])
    const [isLoadingData ,setIsLoadingData] =useState(false)
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page)
    };

    const getData =(page= 1)=>{
        setIsLoadingData(true)
        axiosConfig.get(`recommended-programs/all-recommended-programs-with-pagination?page=${page}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`,'accept-language':'en' }
        }).then(res=>{
            setIsLoadingData(false)
            // setCategories(res.data.data)
            setUserData(res?.data?.data?.data)
            setTotalPages(res?.data?.data?.pages)
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
            toast.error(errors[error][0])
            })
        })
    }
    const getPlaces =(data)=>{
        axiosConfig.get(`places/all-places`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`, 'accept-language':'en'}
        }).then(res=>{
            setPlaces(res.data.data)
        }).catch(err=>{
        })
    }
    useEffect(()=>{
        getData(1)
        getPlaces()
    }, [])

    return (
        <section>
            <ListingHeader/>
            <div className={styles['table-cont']}>
                <div className={styles['table__header-wrapper']}>
                    <h1 className={styles['table__title']}>Recommended Programs</h1>
                    <RecommendedProgramsTableHeader getData={getData} places={places}/>
                    {/* <AddUserModal/> */}
                </div>
                <PortalRecommendedProgramsTable userData={userData} getData={getData} isLoadingData={isLoadingData} places={places}/>
                {/* <PortalTablePagination/> */}
                <PortalTablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        </section>
    )
}

export default RecommendedPrograms