import React from 'react'
import { Pagination } from 'react-bootstrap'
import './PortalTablePagination.css'

const PortalTablePagination = ({ totalPages, currentPage, onPageChange }) => {

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageLinks = 5; // Maximum number of page links to show
    let startPage = Math.max(1, currentPage - Math.floor(maxPageLinks / 2));
    let endPage = Math.min(totalPages, startPage + maxPageLinks - 1);

    if (endPage - startPage < maxPageLinks) {
      startPage = Math.max(1, endPage - maxPageLinks + 1);
    }

    // Render each page number
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => i !== currentPage && onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <Pagination>
        <Pagination.Prev
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
        >
          &lt;
        </Pagination.Prev>

        {renderPageNumbers()}

        <Pagination.Next
          onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
        >
          &gt;
        </Pagination.Next>
      </Pagination>
    </>
  );
};

export default PortalTablePagination;
