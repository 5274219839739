import PortalTablePagination from 'components/Global/Elements/PortalTablePagination/PortalTablePagination'
import React, { useEffect, useState } from 'react'
import styles from './TourGuides.module.css'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import ListingHeader from 'components/Global/Elements/ListingHeader/ListingHeader'
import TourGuidesTableHeader from 'components/Portal/TourGuides/TourGuidesTableHeader/TourGuidesTableHeader'
import PortalTourGuidesTable from 'components/Portal/TourGuides/PortalTable/PortalTable'

function TourGuides() {
    const [userData ,setUserData] =useState([])
    const [neighboorhoods ,setNeighboorhoods] =useState([])
    const [languages ,setLanguages] =useState([])
    const [isLoadingData ,setIsLoadingData] =useState(false)
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page)
    };

    const getData =(page= 1)=>{
        setIsLoadingData(true)
        axiosConfig.get(`tour-guides/all-tour-guides-with-pagination?page=${page}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            setIsLoadingData(false)
            // setCategories(res.data.data)
            setUserData(res?.data?.data?.data)
            setTotalPages(res?.data?.data?.pages)
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
            toast.error(errors[error][0])
            })
        })
    }
    const getNeighboorhood =(data)=>{
        axiosConfig.get(`neighborhood/all-neighborhoods`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`, 'accept-language':'en'}
        }).then(res=>{
            setNeighboorhoods(res.data.data)
        }).catch(err=>{
        })
    }
    const getLanguages =(data)=>{
        axiosConfig.get(`languages/all-languages`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`, 'accept-language':'en'}
        }).then(res=>{
            setLanguages(res.data.data)
        }).catch(err=>{
        })
    }
    useEffect(()=>{
        getData(1)
        getNeighboorhood()
        getLanguages()
    }, [])

    return (
        <section>
            <ListingHeader/>
            <div className={styles['table-cont']}>
                <div className={styles['table__header-wrapper']}>
                    <h1 className={styles['table__title']}>Tour Guides</h1>
                    <TourGuidesTableHeader getData={getData} neighboorhoods={neighboorhoods} languages={languages}/>
                    {/* <AddUserModal/> */}
                </div>
                <PortalTourGuidesTable userData={userData} getData={getData} isLoadingData={isLoadingData} neighboorhoods={neighboorhoods} languages={languages}/>
                {/* <PortalTablePagination/> */}
                <PortalTablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        </section>
    )
}

export default TourGuides